<template>
  <v-container>
  <v-progress-linear
   buffer-value="0"
    color="light-blue"
    height="10"
     stream
    :value="value"
    striped
  ></v-progress-linear>
    </v-container>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style>
</style>