<template>
  <v-card elevation="2" outlined class="mx-auto" max-width="598">
    <div class="text-center pa-2">
      <h2 class="mb-3">{{ $t("view.PageMainDiscoverQuiz.my_score") }}</h2>
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="(score / length) * 100"
        :color="color"
      >
        {{ (score / length) * 100 }} %
      </v-progress-circular>
      <h2>{{ score }}/{{ length }}</h2>
      <h3>{{ $t("view.PageMainDiscoverQuiz.time") }}: {{ time }} {{ $t("view.PageMainDiscoverQuiz.secs") }}</h3>
      <v-btn class="mt-3" depressed color="primary" @click="restart()">{{
        $t("action.playAgain")
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["questions", "length", "color", "score", "time"],
  methods: {
    restart() {
      this.$emit("restart");
    },
  },
};
</script>

<style>
</style>