<template>
  <v-container>
    <v-card
      v-if="progress < 110"
      elevation="2"
      outlined
      class="mx-auto"
      max-width="598"
    >
      <h4 class="text-center pa-1">{{ questions[0].name }}</h4>
      <Progress :value="progress" />
      <v-row>
        <v-col>
          <div class="text-left pl-3">
            <p class="font-weight-medium green--text text--lighten-1">
              {{ formattedTime }}
            </p>
          </div>
        </v-col>
        <v-col>
          <div class="text-right pr-3">
            <p class="font-weight-medium green--text text--lighten-1">
              {{ score }} {{ $t("view.PageMainDiscoverQuiz.points") }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="transition"
      elevation="2"
      outlined
      class="mx-auto mt-3"
      max-width="598"
    >
      <div
        v-for="(question, index) in questions.slice(a, b)"
        :key="index"
        v-show="quiz"
      >
        <v-card-text>
          <div class="pb-3 text-h5">
            {{ $t("string.question_prefix") }}{{ b }}.
          </div>
          <div class="font-weight-medium pa-2" v-html="question.question"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-for="(option, index) in question.options"
                :key="index"
              >
                <v-btn
                  @click="selectResponse(option, a)"
                  :color="isAnswer ? check(option) : ''"
                  elevation="2"
                  block
                  outlined
                  class="no-uppercase"
                  >{{ option.text }} <v-spacer></v-spacer>
                  <v-icon v-if="option.myAnswer && option.isAnswer" dark right>
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="option.myAnswer && !option.isAnswer" dark right>
                    mdi-alpha-x-circle
                  </v-icon>
                  <v-icon
                    v-if="!option.myAnswer && isAnswer && option.isAnswer"
                    dark
                    right
                    color="success"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </div>
    </v-card>
    <v-expand-transition>
      <Score
        v-if="score_show"
        :time="laps"
        :color="scoreColor()"
        :score="score"
        :questions="questions"
        :length="questions.length"
        @restart="restartQuiz"
      />
    </v-expand-transition>
    <v-expand-transition>
      <MyEntry v-if="score_show" :questions="questions" class="pa-3" />
    </v-expand-transition>
  </v-container>
</template>

<script>
import CorrectAnswer from "/public/sounds/CorrectAnswer.mp3";
import WrongAnswer from "/public/sounds/WrongAnswer.mp3";
import GameOver from "/public/sounds/GameOver.mp3";
import GameStart from "/public/sounds/GameStart.mp3";
import Progress from "@/components/Exercise/Progress.vue";
import Score from "@/components/Exercise/Score.vue";
import MyEntry from "@/components/Exercise/MyEntry.vue";
import axios from "axios";
export default {
  props: ["code"],
  data() {
    return {
      questions: [],
      a: 0,
      b: 1,
      answeredIndex: null,
      next: true,
      score_show: false,
      quiz: true,
      score: 0,
      isAnswer: false,
      progress: 10,
      correct: "",
      transition: false,
      timerState: "stopped",
      currentTimer: 0,
      formattedTime: "00:00:00",
      ticker: undefined,
      laps: "",
      latestLap: "",
      snackbar: false,
      data: {
        lang: null,
        count: 10,
        key: this.code,
      },
      myAnswer: false,
    };
  },
  components: {
    Progress,
    Score,
    MyEntry,
  },
  created() {},
  methods: {
    getQuestions() {
      this.data.lang = this.$_getLocale();
      this.data.showOption = false;
      axios
        .post(this.$api.servers.question + "/questions", this.data)
        .then((response) => {
          this.questions = response.data;
        });
    },
    selectResponse(option, index) {
      if (this.answeredIndex != index) {
        this.answeredIndex = index;
        setTimeout(() => {
          this.transition = false;
        }, 1000);

        option.myAnswer = true;
        let correctAnswer = new Audio(CorrectAnswer);
        let wrongAnswer = new Audio(WrongAnswer);
        if (option.isAnswer) {
          correctAnswer.play();
          correctAnswer.volume = 0.2;
          this.correct = "success";
        } else {
          wrongAnswer.play();
          wrongAnswer.volume = 0.2;
          this.correct = "error";
        }
        this.isAnswer = true;
        this.next = false;
        if (option.isAnswer) {
          this.score++;
        }
        this.nextQuestion();
      }
    },
    check(status) {
      if (status.myAnswer && status.isAnswer) {
        return "success";
      } else if (status.myAnswer && !status.isAnswer) {
        return "error";
      } else {
        this.disabled = true;
        return "rgb(0, 0, 0, 0.6)";
      }
    },
    scoreColor() {
      if (this.score < 5) {
        return "error";
      } else {
        return "success";
      }
    },

    nextQuestion() {
      let gameOver = new Audio(GameOver);
      setTimeout(() => {
        this.transition = true;
      }, 1200);
      setTimeout(() => {
        this.progress = this.progress + 100 / this.questions.length;
        if (this.questions.length - 1 == this.a) {
          this.score_show = true;
          this.quiz = false;
          this.transition = true;
          gameOver.play();
          gameOver.volume = 0.1;
          this.lap();
        } else {
          this.a++;
          this.b++;
          this.isAnswer = false;
          this.next = true;
          this.correct = "";
        }
      }, 1000);
    },
    startTimer() {
      if (this.timerState !== "running") {
        this.tick();
        this.timerState = "running";
      }
    },
    stopTime() {
      window.clearInterval(this.ticker);
      this.currentTimer = 0;
      this.formattedTime = "00:00:00";
      this.timerState = "stopped";
    },
    lap() {
      this.laps = this.currentTimer;
      this.latestLap = this.formatTime(this.currentTimer);
      this.currentTimer = 0;
      this.stopTime();
    },
    tick() {
      this.ticker = setInterval(() => {
        this.currentTimer++;
        this.formattedTime = this.formatTime(this.currentTimer);
      }, 1000);
    },
    formatTime(seconds) {
      let measuredTime = new Date(null);
      measuredTime.setSeconds(seconds);
      let MHSTime = measuredTime.toISOString().substr(11, 8);
      return MHSTime;
    },
    restartQuiz() {
      let gameStart = new Audio(GameStart);
      Object.assign(this.$data, this.$options.data());
      this.timerState = "stopped";
      this.startTimer();
      setTimeout(() => {
        this.transition = true;
      }, 1000);
      gameStart.play();
      gameStart.volume = 0.1;
      (this.data.key = this.$route.params.code), this.getQuestions();
    },
  },
  beforeMount() {
    this.transition = true;
    this.getQuestions();
    this.startTimer();
    this.code = this.$route.params.code;
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
</style>