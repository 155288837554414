<template>
  <v-container>
    <v-expand-transition>
      <Question v-if="reveal" :code="code" />
      <v-card v-else outlined class="mx-auto" max-width="598">
        <v-card-text>
          <div class="text-caption mb-3">{{$t('route.PageMainDiscoverQuiz')}}</div>
          <p class="text-h4 text--primary">{{ questions[0].name }}</p>
          <div class="text--primary">{{ $t("view.PageMainDiscoverQuiz.total_questions") }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleClick()">
            {{ $t("action.start") }}
          </v-btn>
        </v-card-actions>
        <!-- <div class="text-center pt-3">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/p7rGLzITPPg"
          ></iframe>
        </div> -->
      </v-card>
    </v-expand-transition>
  </v-container>
</template>

<script>
import Question from "@/components/Exercise/Question.vue";
import GameStart from "/public/sounds/GameStart.mp3";
import axios from "axios";
export default {
  props: ["code"],
  data: () => ({
    questions: [],

    data: {
      lang: null,
      count: 1,
      key: null,
    },
    reveal: false,
  }),

  components: {
    Question,
  },
  methods: {
    getQuestions() {
      this.data.key = this.$route.params.code;
      this.data.lang = this.$_getLocale();
      axios
        .post(this.$api.servers.question + "/questions", this.data)
        .then((response) => {
          this.questions = response.data;
        });
    },
    handleClick() {
      let gameStart = new Audio(GameStart);
      gameStart.play();
      gameStart.volume = 0.1;
      this.reveal = true;
    },
  },
  beforeMount() {
    this.getQuestions();
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
</style>