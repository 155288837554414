<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
    <Introduction :code="$route.params.code" />
    
</v-container>
</template>

<script>
  import Introduction from "../../components/Exercise/Introduction.vue"
  import { mapState } from 'vuex'
  export default {
    components:{
      Introduction,
    },
    computed: mapState({
      //
    }),
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainDiscover"),
        to:{name:'PageMainDiscover'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainDiscoverQuiz"),
        to:{name:'PageMainDiscoverQuiz'},
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>