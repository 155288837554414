<template>
  <v-container>
    <v-card outlined class="mx-auto" max-width="598">
      <h2 class="text-center pa-2">{{ $t("view.PageMainDiscoverQuiz.my_entry") }}</h2>
      <h4 class="text-left pl-3">{{ questions[0].name }}</h4>
       <v-divider></v-divider>
      <div v-for="(question, index) in questions" :key="index">
        <v-card-text>
          <div class="pb-3">{{$t('string.question_prefix')}}{{ index + 1 }}.</div>
          <div class="font-weight-medium pa-2" v-html="question.question"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-for="(option, index) in question.options"
                :key="index"
              >
                <div>
                  <v-btn
                    v-if="option.isAnswer && option.myAnswer"
                    color="success"
                    elevation="2"
                    block
                    outlined
                    class="no-uppercase my-answer"
                    >{{ option.text }}   <v-spacer></v-spacer>
                    <v-icon v-if="option.myAnswer" dark right>
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </v-btn>
                   <v-btn
                    v-if="option.isAnswer && !option.myAnswer"
                    color="rgb(0, 0, 0, 0.6)"
                    elevation="2"
                    block
                    outlined
                    class="no-uppercase"
                    >{{ option.text }}   <v-spacer></v-spacer>
                    <v-icon  color="success" dark right>

                      mdi-checkbox-marked-circle
                    </v-icon>
                  </v-btn>
                  <v-btn class="no-uppercase my-answer" v-if="!option.isAnswer && option.myAnswer" color="error" elevation="2" block outlined
                    >{{ option.text }}   <v-spacer></v-spacer>
                    <v-icon v-if="option.myAnswer" dark right>
                      mdi-alpha-x-circle
                    </v-icon>
                  </v-btn>
                   <v-btn class="no-uppercase" v-if="!option.isAnswer && !option.myAnswer" color="rgb(0, 0, 0, 0.6)" elevation="2" block outlined
                    >{{ option.text }}   <v-spacer></v-spacer>
                    <v-icon v-if="option.myAnswer" dark right>
                      mdi-alpha-x-circle
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
         <v-divider></v-divider>
      </div>
      
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["questions"],
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
.my-answer{
  background-color: #FFE082;
}
</style>